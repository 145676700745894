<template>
  <div class="orgnazation-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }"
        >考勤情况</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/zuzhi/organizationList/myOrganizationList1',
          query: {
            oid: this.oid,
            fatherName: this.fatherName
          }
        }"
        >我的组织详情</el-breadcrumb-item
      >

      <el-breadcrumb-item>{{ fatherName }} </el-breadcrumb-item>
    </el-breadcrumb>

    <!-- <el-button
      style="margin-top: 0px;margin-bottom: 2px;font-size:12px;"
      @click="back"
      type="primary"
      icon="el-icon-arrow-left"
      >返回</el-button
    > -->
    <div id="frozen-btn">
      <button @click="back" class="green">
        返回
      </button>
    </div>
    <el-card class="history-attendance-right">
      <div>
        <i class="el-icon-suitcase"></i>
        <span>下级组织名称</span>
        <div style="font-size:30px;font-weight:bold;" class="course-title">
          {{ childName }}
        </div>
      </div>
    </el-card>
    <div class="queryDigital">
      <!-- <div class="detail-button">
        <el-button type="primary" @click="handleDetail">查看详情 </el-button>
      </div> -->
      <div class="up">
        <el-card class="up-left">
          <el-form :model="digitalForm" ref="digitalForm">
            <el-form-item label="学年" prop="yesr" label-width="40px">
              <el-input v-model="digitalForm.yesr" style="width:212px">
                <template slot="append">
                  <el-select v-model="digitalForm.yesr">
                    <el-option
                      v-for="year in years"
                      :key="year"
                      :label="year"
                      :value="year"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="学期" prop="semester" label-width="40px">
              <el-input v-model="digitalForm.semester" style="width:212px">
                <template slot="append">
                  <el-select v-model="digitalForm.semester">
                    <el-option label="上" value="上"></el-option>
                    <el-option label="下" value="下"></el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="周次" prop="week" label-width="40px">
              <el-input v-model="digitalForm.week" style="width:212px">
                <template slot="append">
                  <el-select v-model="digitalForm.week" placeholder="选择周次">
                    <el-option
                      v-for="week in weeks"
                      :key="week"
                      :label="week"
                      :value="week"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="节次" label-width="40px">
              <el-select
                v-model="digitalForm.startPeriod"
                placeholder="开始节"
                style="width: 100px;margin-right:3px; "
              >
                <el-option
                  v-for="period in periods"
                  :key="period"
                  :label="period"
                  :value="period"
                ></el-option>
              </el-select>
              <span>-</span>
              <el-select
                v-model="digitalForm.endPeriod"
                placeholder="结束节"
                style="width: 100px;margin-left:3px;"
              >
                <el-option
                  v-for="period in periods"
                  :key="period"
                  :label="period"
                  :value="period"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button
              type="success"
              size="small"
              class="conclude"
              @click="getStatics"
              >统计
            </el-button>
          </el-form>
        </el-card>
        <el-card class="up-right">
          <!-- <div ref="lineChart" style="width: 100%; height: 400px;"></div> -->
          <div ref="pieChart" style="width: 100%; height: 450px;" />
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons')
import resize from '../../../home/components/mixins/resize'
export default {
  mixins: [resize],
  data() {
    return {
      oid: '',
      fatherName: '',
      childName: '',
      digitalForm: {
        yesr: '2024',
        semester: '上',
        week: '周一',
        startPeriod: 1,
        endPeriod: 2
      },
      years: ['2023', '2024', '2025', '2026', '2027'],
      weeks: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      periods: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12],
      lineChart: null,
      chartData: {
        xAxisData: ['签到率', '迟到率', '请假率', '旷课率', '其他'],
        seriesData: [80, 70, 60, 50, 40] // 示例数据
      },
      pieData: [{ value: 1, name: '已签到' }]
    }
  },
  created() {
    // this.childName = window.sessionStorage.getItem('childName')
    // this.fatherName = window.sessionStorage.getItem('fatherName')
    this.fatherName = this.$route.query.fatherName
    this.oid = this.$route.query.oid
    this.childName = this.$route.query.childName
  },
  mounted() {
    // this.initLineChart()
    this.initPieChart()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    //初始化柱状图
    // initLineChart() {
    //   this.mychart = echarts.init(this.$refs.lineChart)
    //   const option = {
    //     xAxis: {
    //       typeof: 'category',
    //       data: this.chartData.xAxisData,
    //       axisTick: {
    //         show: true
    //       },
    //       axisLine: {
    //         show: true
    //       }
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'shadow'
    //       }
    //     },
    //     yAxis: {
    //       type: 'value',
    //       axisLine: {
    //         show: false
    //       },
    //       axisLabel: {
    //         show: false
    //       },
    //       axisLine: {
    //         show: true
    //       },
    //       splitNumber: 5,
    //       interval: 20, // 设置分割线间隔
    //       axisLabel: {
    //         formatter: '{value}%' // 显示百分比
    //       }
    //     },
    //     series: [
    //       {
    //         data: this.chartData.seriesData,
    //         type: 'line',
    //         symbol: 'circle', // 数据点形状
    //         symbolSize: 8, // 数据点大小
    //         label: {
    //           show: true, // 显示数据标签
    //           formatter: '{c}%' // 数据标签格式
    //         },
    //         lineStyle: {
    //           color: '	#00FFFF' // 设置折线颜色为淡蓝色
    //         },
    //         itemStyle: {
    //           color: '		#00FFFF' // 设置折线上的圆点颜色为淡蓝色
    //         }
    //       }
    //     ]
    //   }
    //   this.mychart.setOption(option)
    // },
    initPieChart() {
      // 初始化饼状图
      const pieChart = echarts.init(this.$refs.pieChart, 'macarons')
      // 饼状图配置
      const pieOption = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          top: '5%',
          left: 'center',
          data: this.pieData.map(item => item.name)
        },
        series: [
          {
            name: '考勤情况',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            // avoidLabelOverlap: false,
            // label: {
            //   show: false,
            //   position: 'center'
            // },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '30',
            //     fontWeight: 'bold'
            //   }
            // },
            // labelLine: {
            //   show: false
            // },
            data: this.pieData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      }
      // 设置饼状图配置
      pieChart.setOption(pieOption)
    },
    async getStatics() {
      let section = `第${this.digitalForm.startPeriod}-${this.digitalForm.endPeriod}节课`
      const { data: res } = await this.$http.get('/org/statisticorganization', {
        params: {
          oid: this.oid,
          year: this.digitalForm.yesr,
          semester: this.digitalForm.semester,
          week: this.digitalForm.week,
          section: section
        }
      })
      if (res.code == 200) {
        this.pieData = [
          { value: res.data[0]['迟到'], name: '迟到' },
          { value: res.data[0]['请假'], name: '请假' },
          { value: res.data[0]['未认证'], name: '未认证' },
          { value: res.data[0]['其他'], name: '其他' },
          { value: res.data[0]['已签到'], name: '已签到' },
          { value: res.data[0]['旷课'], name: '旷课' },
          { value: res.data[0]['签到'], name: '签到' }
        ]
        this.initPieChart()
      } else if (res.code === 198) {
        this.$message.warning(res.message)
      } else {
        this.$message.error('查询失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orgnazation-container {
  padding: 20px 20px 0 20px;
  #frozen-btn {
    button {
      border: 0;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 10px;
      border-radius: 4px;
      color: white;
      outline: none;
      position: relative;
      margin-bottom: 5px;
    }

    button:before {
      content: '';
      display: block;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0.4) 50%
      );
      background-size: 210% 100%;
      background-position: right bottom;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 4px;
      transition: all 1s;
      -webkit-transition: all 1s;
    }
    .green {
      background-image: linear-gradient(to right, #25aae1, #40e495);
      box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }
    .green:hover:before {
      background-position: left bottom;
    }
  }
  .queryDigital {
    display: flex;
    margin: 10px 20px 10px 20px;
    flex-direction: column;
    border: 1px solid rgba(239, 239, 239, 1);
    align-items: center;
    border-radius: 5px;
    background: white;
    box-shadow: 0px 2px 6px 5px rgba(239, 239, 239, 1);
    .up {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      min-width: 85vw;
      height: 500px;
      .up-left {
        width: 30%;
        height: 80%;
        display: flex;
        flex-direction: column;
        padding: 30px;
        // margin-left: 5%;
        margin: auto;
        .el-card__body {
          padding: 0;
        }

        .conclude {
          float: right;
        }
      }

      .up-right {
        width: 50%;
        height: 80%;
        padding-left: 30px;
        // margin-right: 5%;
        margin: auto;
      }
    }
  }
  .course-title {
    text-align: center;
  }
  .el-button {
    margin: 0;
  }
}
</style>
